@keyframes rotateAnimation {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}
  
.lgy-loading-icon {
  margin-left: auto;
  margin-right: auto;
  font-size: 0;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  padding: 0;
  border: 3px solid white;
  border-bottom: 3px solid #0071bc;
  border-left: 3px solid #0071bc;
  background-color: transparent;
  animation-name: rotateAnimation;
  animation-duration: 1s;
  animation-delay: .2s;
  animation-iteration-count: infinite;
}
